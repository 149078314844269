import React from "react";
import styled from "styled-components";

// Sample package data
const packagesData = [
  {
    excution_time: "2 months",
    description:
      "This premium package offers a comprehensive solution for your needs. It includes extended features, priority support, and a dedicated account manager. Perfect for businesses looking to scale efficiently.",
    price: 1875501,
    badge:
      "https://png.pngtree.com/png-clipart/20220602/original/pngtree-premium-badges-png-image_7885137.png", // Replace with actual badge image URL
    title: "Premium Package",
  },
  {
    excution_time: "3 months",
    description:
      "This standard package provides a balanced set of features suitable for most users. It includes standard support and all the necessary tools to get your project up and running.",
    price: 1250000,
    badge:
      "https://png.pngtree.com/png-clipart/20220222/original/pngtree-original-product-badge-png-image_7303903.png", // Replace with actual badge image URL
    title: "Standard Package",
  },
  {
    excution_time: "1 month",
    description:
      "The basic package is ideal for individuals or small teams. It includes essential features and limited support, making it a cost-effective option for small-scale projects.",
    price: 500000,
    badge:
      "https://images.squarespace-cdn.com/content/v1/5c2864609f8770b74f18cbb8/1565118033789-1JYNE10IZU8U0ELLF2WL/Basic+Package.png?format=1000w", // Replace with actual badge image URL
    title: "Basic Package",
  },
  // Add more packages as needed
];

const PackagesSection = styled.section`
  background-color: #fff;
  padding: 10px 20px 10px;
`;

const PackagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px !important;
`;

const PackageCard = styled.div`
  background-color: #fff; /* Changed background to white */
  color: #333; /* Changed text color to dark */
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 20px;
  gap: 10px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PackageBadge = styled.img`
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 10px;
  height: 140px;
  object-fit: contain;
  max-width: 100px; /* Adjust max-width as needed */
`;

const PackageTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;
  color: black;
  margin: 0 !important;
  height: 35px;
`;

const PackageExecutionTime = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  color: black;
  margin: 0 !important;
  height: 35px;
`;

const PackageDescription = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
  color: black;
  min-height: 200px;
`;

const PackagePrice = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: black;
`;

const PackagesComponent = ({
  newPackages,
  selectPackege,
  setSelectedPackege,
  setDownPayment,
  setPackPrice,
}) => {
  return (
    <PackagesSection>
      <div className="container">
        <h2
          style={{ textAlign: "center", marginBottom: "20px", color: "black" }}
        >
          Our Packages
        </h2>
        <p
          style={{
            textAlign: "center",
            fontSize: "16px",
            marginBottom: "40px",
            color: "black",
          }}
        >
          Choose from our selection of packages designed to meet your needs.
        </p>
        <PackagesContainer>
          {newPackages?.map((pkg, index) => (
            <div
              style={{
                border: pkg.id == selectPackege?.id ? "2px solid red" : "0",
              }}
              onClick={() => {
                setSelectedPackege(pkg);
                setDownPayment(parseFloat(pkg.price) * (10 / 100));
                setPackPrice(parseFloat(pkg.price));
              }}
            >
              <PackageCard key={index}>
                <PackageBadge src={pkg.badge} alt={pkg.title} />
                <PackageTitle>{pkg.title}</PackageTitle>
                <PackageExecutionTime>
                  Execution Time: {pkg.excution_time}
                </PackageExecutionTime>
                <PackageDescription>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: pkg.description ? pkg.description : "<p></p>",
                    }}
                  ></p>
                </PackageDescription>
                <PackagePrice>
                  Price: ${pkg.price.toLocaleString()}
                </PackagePrice>
                <button className="btn btn-success">Choose</button>
              </PackageCard>
            </div>
          ))}
        </PackagesContainer>
      </div>
    </PackagesSection>
  );
};

export default PackagesComponent;
