import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "./TestimonialsSection.css";

const DuncanSections = ({ newEstate }) => {
  return (
    <section className="installation-section">
      <div className="container_custom" style={{ padding: "0" }}>
        <div className="section-title text-center">
          <p
            style={{
              margin: "0 auto",
              width: "min(200px, 100%)",
              fontSize: "23px",
            }}
          >
            <b>Wade Duncan Ready To Get Started ?</b>
          </p>
          <p
            className="text-var(--grey-border-200)"
            style={{
              margin: "0 auto",
              color: "darkgray",
              width: "min(800px, 100%)",
              fontSize: "16px",
            }}
          >
            Simply Fill out the short agreement from below to get started.
            please note projects require a deposite or pre-approved financing to
            secure your spot on your project calender
          </p>
          <h1 className="proposalshape">Your Peoposal Agreement</h1>
        </div>

        <div className="form-wrapper">
          <h3 className="form-title">Verify You Details Below</h3>
          <p>
            ensure this represents your information and the project address.
          </p>
          <form className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                className="form-control"
                placeholder="Enter your name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                className="form-control"
                placeholder="Enter your address"
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                className="form-control"
                placeholder="Enter your phone number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder="Enter your email"
              />
            </div>
            {/* <button type="submit" className="btn-submit">Submit</button> */}
          </form>
        </div>
      </div>
    </section>
  );
};

export default DuncanSections;
