import Home from "../pages/HomePage/Home";
import Login from "../pages/LoginPage/Login";


export const routes = [
  {
    path:'/',
    component: <Home />
  },
  {
    path:'/login',
    component:  <Login />
  },
 
]