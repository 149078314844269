

import React, { useEffect, useState } from 'react'
import "./style.css"
import CheckBox from './../../checkbox/CheckBox';
import axios from 'axios';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const LoginContainer = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    name: '',
    password: '',
  })
  useEffect(() => {

    const handleMutation = (mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const parentElement = document.querySelector("#paymentcalculatorwidget");
          parentElement.style.display = "none"
        }
      }
    };


    const observer = new MutationObserver(handleMutation);


    observer.observe(document.body, { childList: true, subtree: true });


    return () => observer.disconnect();
  }, []);

  const [loginLoading, setLoginLoading] = useState(false)
  const login = () => {
    setLoginLoading(true)
    const data_send = {
      user_name: loginData?.name,
      password: loginData?.password
    }
    axios.post("https://camp-coding.site/primiers/api/real_states/getreal_details", data_send)
      .then((res) => {
        if (res.data.status == 'success') {
          localStorage.setItem("PLoggedIn", true);
          localStorage.setItem("PUser", JSON.stringify(data_send));
          window.location.href = '/' + res.data?.result?.id
        }
        else if (res.data.status == 'faild') {
          toast.error(res.data.message)
        }
        else {
          toast.error('Something Went Error')
        }
      })
      .catch(e => console.log(e))
      .finally(() => {
        setLoginLoading(false)
      })
  }

  useEffect(() => {
    localStorage.removeItem("PLoggedIn")
    localStorage.removeItem("PUser")
  }, [])
  return (
    <div className='login_container' >
      <form onSubmit={(e) => {
        e.preventDefault()
        login()
      }} action="">

        <div className='login_continer_content'>
          <div className=''>
            <h5>Username or Email Address</h5>
            <div>
              <input onChange={(e) => {
                setLoginData({ ...loginData, name: e.target.value })
              }} type="text" placeholder='' />
            </div>
          </div>
          <div>
            <h5>Password</h5>
            <div>
              <input onChange={(e) => {
                setLoginData({ ...loginData, password: e.target.value })
              }} type="text" />
            </div>
          </div>
          <div className='remeber_me'>

            <CheckBox id={"remember"} />
            <label htmlFor="remember">Remeber me</label>
          </div>
          {
            loginLoading ? (
              <div>
                <p style={{ textAlign: 'center' }}>loading....</p>
              </div>
            ) : (
              <div className='login_btn'>
                <button className="btn btn-success">Log in</button>
              </div>
            )
          }



        </div>
      </form>



    </div>
  )
}

export default LoginContainer
