import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import './RealEstateDescription.css';
import clipPath from  '../../../assets/images/IMG_0708 (1).PNG'

function RealEstateDescription({newEstate , newPackages}) {
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [validUntil, setValidUntil] = useState(null);

  // Calculate time remaining function
  function calculateTimeRemaining() {
    if (!newEstate || !newEstate.end_date) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const endDate = new Date(newEstate.end_date).getTime();
    const now = new Date().getTime();
    let distance = endDate - now;

    if (distance < 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  // Calculate valid until date function
  function calculateValidUntil() {
    if (!newEstate || !newEstate.end_date) {
      return '';
    }

    const endDate = new Date(newEstate.end_date);
    const validUntilDate = new Date(endDate.getTime()); // Subtract 30 days

    const formattedDate = validUntilDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    return formattedDate;
  }

  // Update time remaining and valid until date every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
      setValidUntil(calculateValidUntil());
    }, 1000);
    return () => clearInterval(interval);
  }, [newEstate]);

  useEffect(() => {
    if (timeRemaining && validUntil) {
      if (!timeRemaining?.days && !timeRemaining?.hours && !timeRemaining?.minutes && !timeRemaining?.seconds) {
        alert("You Proposal Ended");
        window.location.href = "/login"
      }
    }
  }, [timeRemaining])
  return (
    <div className="real-state-page-container">
      {/* <img src={clipPath} className='real-state-img'/> */}
       <div className='real-state-content-container'>
        <div>
        <p>Hello</p>
        <h3>{newEstate.user_name}</h3>
        </div>
       <div>
       <p>Your custom proposal</p>
       <h3>Starting as low as: ${newPackages}</h3>
       </div>
       <div><p>Project Address:</p>
       <h3>{newEstate.location}</h3></div>
       <div>
       <p>Be sure to accept before pricing expires*</p>
       <h3>{timeRemaining?.days}d {timeRemaining?.hours}h {timeRemaining?.minutes}m {timeRemaining?.seconds}s</h3>
       </div>
      <div>
      <p>Proposal Date</p>
      <h3>{validUntil}</h3>
      </div>
       </div>
       {/* <img src={clipPath} className='real-state-clippath'/> */}
    </div>
  );
}

export default RealEstateDescription;
