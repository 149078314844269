import "./App.css";
import AppRoutes from "./Routes/Routes";
import DefaultLayout from "./layouts/defaultLayout";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className='App'>
      <DefaultLayout>
        {<AppRoutes />}
      </DefaultLayout>
      <ToastContainer />
    </div>
  );
}

export default App;
