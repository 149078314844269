import React from "react";

import {routes} from "./routesData";

import {Routes, Route} from "react-router-dom";
import Home from "../pages/HomePage/Home";







const AppRoutes = () => {
  return (
    <div>
      <Routes>
        {routes.map((item, index) => {
          return (
            <Route path={item.path} element={item.component} key={index} />
          );
        })}
        <Route path='/:id' element={<Home />} />
      </Routes>
    </div>
  );
};

export default AppRoutes;
