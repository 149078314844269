import premierLogo from '../../../assets/images/RE.png';
import './style.css';
import stoneImg1 from '../../../assets/images/stone-floor-texture_1194-9009 (1).avif';
import stoneImg2 from '../../../assets/images/stone-floor-texture_1194-9009.avif';
import stoneImg3 from '../../../assets/images/stone-texture-background_1194-8448.avif';
import nameImg from '../../../assets/images/Screenshot_2024-08-14_153452-removebg-preview.png'
import { useState } from 'react';
import CheckBox from '../../checkbox/CheckBox';


export default function RoofingShingles({ selectedPackege, setSelectedPackege, newEstate, sections }) {
  const [packageData, setPackageData] = useState(sections && sections?.length && sections[0]?.id)
  console.log("sections", sections)
  return (
    <div className='roofing-page-container'>
      <div className='roofing-header'>
        <img src={premierLogo} />
        <h3>Roofing Shingles</h3>
        <img src={premierLogo} />
      </div>

      <ul className='roofing-list'>
        {newEstate?.packages?.map(packagee => <li className={(packageData ? packageData : sections[0]?.id) == packagee.id ? "active" : ""} onClick={() => setPackageData(packagee?.id)} key={packagee?.id}>{packagee?.title}</li>)}
      </ul>
      <div className="accordion" style={{ maxWidth: "800px", width: "100%", margin: "40px  auto 0px" }}>
        {sections && sections?.length && Array.isArray(sections) && sections?.map((section, index) => (
          section.id == (packageData ? packageData : sections[0]?.id) ?
            <div key={index} className="accordion-item">
              <div
              >
                <div className="accordion-body">
                  <div className="d-flex align-items-center gap-10">
                    {" "}
                    <img
                      src="https://premiergrouproofs.com/wp-content/uploads/2023/05/premier-group-roofs-logo-dark.png"
                      alt=""
                      style={{
                        width: "250px",
                        height: "100px",
                        objectFit: "contain",
                        marginBottom: "19px",
                      }}
                      className='accordion-roofing-img'
                    />
                    <p>
                      <b>Roofing Systems</b>
                    </p>
                  </div>
                  <div
                    className="rowDiv w-100"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <div className="">
                      <h4>{section.title}</h4>
                      <p style={{ width: "calc(100% - 100px)" }} dangerouslySetInnerHTML={{
                        __html: section.
                          description && section.
                            description?.length ? section.
                          description : "<p></p>"
                      }}>


                      </p>
                    </div>
                    <img
                      src={section.badge}
                      alt={section.title}
                      className="package-image"
                    />
                  </div>
                  <div className="package-images">
                    {section.images.map((image, i) => (
                      <img
                        key={i}
                        src={image?.url}
                        alt={`Image ${i}`}
                        className="package-image"
                      />
                    ))}
                  </div>
                  <div className="package-details">
                    <p>
                      <strong>Price:</strong> {section.price}
                    </p>
                    <p>
                      <strong>Duration:</strong> {section.excution_time}
                    </p>
                  </div>
                  <div className="features">
                    <div className="included-features">
                      <h5>Included Features</h5>
                      <ul className="package-features">
                        {section.features
                          .filter((feature) => feature.
                            include
                          )
                          .map((feature, i) => (
                            <li key={i}>
                              <span aria-label="Check" className="imgInclude">
                                ✔
                              </span>{" "}
                              {feature.feature_name}
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="not-included-features">
                      <h5>Not Included Features</h5>
                      <ul className="package-features">
                        {section.features
                          .filter((feature) => !feature.
                            include
                          )
                          .map((feature, i) => (
                            <li key={i}>
                              <span aria-label="Cross" className="imgNotInclude">
                                ✘
                              </span>{" "}
                              {feature.
                                feature_name
                              }
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                  <b>
                    Best Option
                    <hr />
                  </b>
                  <h4>
                    {" "}
                    <b>Total Investments</b>
                  </h4>
                  <p>
                    Price: <b>{section?.price}</b>
                  </p>
                  <div className="remeber_me rowDiv">
                    <CheckBox id={"remember" + index} />
                    <label htmlFor={"remember" + index}>Gutters Prot</label>
                  </div>
                  <div className="login_btn">
                    <button className={`btn ${selectedPackege?.id == section?.id ? "btn-success" : ""}`} onClick={() => { setSelectedPackege(section) }}>
                      {/* <p>Only 190$/mo</p> */}
                      <p>Click For Financing Option</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            : ""
        ))}
      </div>
    </div>
  )
}
