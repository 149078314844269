

import React from 'react'
import "./style.css"
import LoginContainer from './../../components/LoginPage/LoginContainer/LoginContainer';

const Login = () => {
  return (
    <div className='login_page_container'>
      <div className='overlay'></div>
      <div className=''></div>
      <div className='conent'>

        <LoginContainer />
      </div>

      </div>
      
  )
}

export default Login
