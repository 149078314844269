

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./style.css"
import LoginHeader from "../../components/LoginHeader/LoginHeader";
const DefaultLayout = ({ classessName, children }) => {
  return (
    <div

    >
      <LoginHeader />
      <Header/>
      {children}
      <Footer />
    </div>
  );
};

export default DefaultLayout;
