import React, { useEffect, useState } from "react";
import "./style.css";
import FirstSection from "./../../components/HomePage/firstSection/FirstSection";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CompanyDetailsFirst from "../../components/HomePage/secondSection";
import StatsSection from "../../components/HomePage/StatsSection/StatsSection";
import RealEstateDescription from "../../components/HomePage/description";
import StateDescriptionSection from "../../components/HomePage/StateDescriptionSection";
import TestimonialsSection from "../../components/HomePage/TestimonialsSection";
import SignatureComponent from "../../components/HomePage/signature";
import PackagesComponent from "../../components/HomePage/PackagesComponent";
import TermsAndConditions from "../../components/HomePage/termsandconditions";
import InstallationSection from "../../components/HomePage/installationProccess";
import MeasurmentSection from "../../components/HomePage/measurmentsImages";
import RoofingShingles from "../../components/HomePage/RoofingShingles/RoofingShingles";
import DuncanSections from "../../components/HomePage/duncanSections";
import Accordion from "../../components/HomePage/accordion";
import ProposalAgreement from "../../components/HomePage/ProposalAgreement/ProposalAgreement";
import Enhancify from "../../components/HomePage/Enhancify/Enhancify";

const Home = () => {
  const { id } = useParams();
  const [selectPackege, setSelectedPackege] = useState(null);
  const [selectGutter, setSelectedGutter] = useState(null);
  const [selectGuard, setSelectedGuard] = useState(null);
  const [downPayment, setDownPayment] = useState(null);
  const [stndardGutter, setStndardGutter] = useState(null);
  const [guardGutter, setGuardGutter] = useState(null);
  const navigate = useNavigate();
  const [newPackages, setNewPackages] = useState([]);
  const [newEstate, setNewEstate] = useState({});

  const getData = () => {
    axios
      .get(
        "https://camp-coding.site/primiers/api/" +
        "real_states/get_details/" +
        id
      )
      .then((res) => {
        setNewEstate(res?.data?.result);
        try {
          const PUser = JSON.parse(localStorage.getItem("PUser"));
          if (
            PUser?.user_name?.toLowerCase() !=
            res?.data?.result?.user_name?.toLowerCase()
          ) {
            window.location.href = "/login";
          }
        } catch (err) { }
        setNewPackages(res?.data?.result?.packages || []);
      });
  };
  useEffect(() => {
    if (!id || !localStorage.getItem("PLoggedIn")) {
      navigate("/login");
    }
    getData();
  }, []);

  // const sections = [
  //   {
  //     title: "Standard Package",
  //     image:
  //       "https://png.pngtree.com/png-clipart/20220222/original/pngtree-original-product-badge-png-image_7303903.png",
  //     packageImage:
  //       "https://png.pngtree.com/png-clipart/20220222/original/pngtree-original-product-badge-png-image_7303903.png",
  //     packageTitle: "Basic Installation",
  //     packageDescription:
  //       "This package includes essential installation services for small setups. It covers site inspection and assessment, basic hardware setup, installation of up to 5 devices, basic user training, and excludes post-installation cleanup and 24/7 support. Ideal for basic home installations requiring a quick setup and introductory training.",
  //     price: "$150",
  //     duration: "3 hours",
  //     features: [
  //       { description: "Site inspection and assessment", included: true },
  //       { description: "Basic hardware setup", included: true },
  //       { description: "Installation of up to 5 devices", included: true },
  //       { description: "Basic user training", included: true },
  //       { description: "Post-installation cleanup", included: false },
  //       { description: "24/7 Support", included: false },
  //       { description: "Remote monitoring setup", included: false },
  //       { description: "Service warranty for 6 months", included: false },
  //     ],
  //     images: [
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZhOzKU4bKr1ETfW6bBpOsfGjPZ7cNlIQn2Q&s",
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9ytYLgvtzAhVbE-xKY5A8--qkhO4tsGWMqQ&s",
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQApzOXgMllsk9ZPs1kHSSRUQie7sVyri5Q9Q&s",
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdxtV2tNl5Yl_tPPl75Blwm-spr_wmXvdLlw&s",
  //     ],
  //   },
  //   {
  //     title: "Premium Package",
  //     image:
  //       "https://png.pngtree.com/png-clipart/20220602/original/pngtree-premium-badges-png-image_7885137.png",
  //     packageImage:
  //       "https://png.pngtree.com/png-clipart/20220602/original/pngtree-premium-badges-png-image_7885137.png",
  //     packageTitle: "Premium Installation",
  //     packageDescription:
  //       "This package includes premium installation services with additional features. It covers site inspection and assessment, comprehensive hardware setup, installation of up to 10 devices, intermediate user training, and post-installation cleanup. It excludes 3 months of 24/7 support, advanced network configuration, and includes an extended service warranty for 1 year. Ideal for more complex setups requiring thorough installation and additional training and support options.",
  //     price: "$300",
  //     duration: "5 hours",
  //     features: [
  //       { description: "Site inspection and assessment", included: true },
  //       { description: "Comprehensive hardware setup", included: true },
  //       { description: "Installation of up to 10 devices", included: true },
  //       { description: "Intermediate user training", included: true },
  //       { description: "Post-installation cleanup", included: true },
  //       { description: "3 months of 24/7 Support", included: false },
  //       { description: "Advanced network configuration", included: false },
  //       {
  //         description: "Extended service warranty for 1 year",
  //         included: false,
  //       },
  //     ],
  //     images: [
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZhOzKU4bKr1ETfW6bBpOsfGjPZ7cNlIQn2Q&s",
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9ytYLgvtzAhVbE-xKY5A8--qkhO4tsGWMqQ&s",
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQApzOXgMllsk9ZPs1kHSSRUQie7sVyri5Q9Q&s",
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdxtV2tNl5Yl_tPPl75Blwm-spr_wmXvdLlw&s",
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcO2O8qGPPUC5AXw62I7UvXat-EfZJ29g2sA&s",
  //     ],
  //   },
  //   // Add more sections as needed
  // ];

  return (
    <div className="home_container">
      <FirstSection
        newEstate={newEstate}
        newPackages={
          newPackages?.sort(
            (a, b) => parseFloat(a?.price) - parseFloat(b?.price)
          )[0]?.price
        }
      />
      <RealEstateDescription newEstate={newEstate} newPackages={
        newPackages?.sort(
          (a, b) => parseFloat(a?.price) - parseFloat(b?.price)
        )[0]?.price
      } />
      <TestimonialsSection />
      <InstallationSection newEstate={newEstate} />
      <MeasurmentSection newEstate={newEstate} />
      <RoofingShingles newEstate={newEstate} setSelectedPackege={setSelectedPackege} selectedPackege={selectPackege} sections={newPackages?.sort(
        (a, b) => parseFloat(a?.price) - parseFloat(b?.price)
      )} />
      <Enhancify />

      <ProposalAgreement setGuardGutter={setGuardGutter}  setStanderGutter={setStndardGutter} guardGutter={guardGutter} standerGutter={stndardGutter} data={newEstate} newEstate={newEstate} selectedPackege={selectPackege} />
      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">2024 © Premier Group.</div>
            <div class="col-sm-6">
              <div class="text-sm-end d-none d-sm-block">
                Powered By
                <img
                  // height={160}
                  width={160}
                  style={{ cursor: "pointer" }}
                  class="bigbangLogo"
                  src="https://res.cloudinary.com/duovxefh6/image/upload/v1719493363/bigbang-logo_enxqgb.svg"
                  onClick={() => {
                    window.open("https://its.ae", "_blanck");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
