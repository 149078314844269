import React from "react";

import "./style.css"

const CheckBox = ({id, onChange}) => {
  return (
    <div>
      <label class='container'>
        <input id={id}  type='checkbox' onChange={onChange} />
        <div class='checkmark'></div>

      </label>
    </div>
  );
};

export default CheckBox;
