


import React from 'react'

import logo from "../../assets/images/PG_logo.png"
import "./style.css"
import { ChatIcon } from '../../assets/svgIcons'

const LoginHeader = () => {
  return (
    <div className='login_header_container'>
      <div className='login_header_conent'>
        <div></div>
        <div className='headerLogo'>
          <img src={logo} alt="" />
        </div>
        <div className='hl_right_side'>
          {/* <div className='chat_board_btn'>
            <div>{ChatIcon}</div>
            <div>Chat Board</div>
          </div> */}
        </div>
      </div>

    </div>
  )
}

export default LoginHeader
