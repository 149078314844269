import React, { useState, useEffect } from 'react';
import "./style.css";
import premierLogo from '../../../assets/images/PREMIER2.png';


const FirstSection = ({ newEstate, newPackages }) => {
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [validUntil, setValidUntil] = useState(null);

  // Calculate time remaining function
  function calculateTimeRemaining() {
    if (!newEstate || !newEstate.end_date) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const endDate = new Date(newEstate.end_date).getTime();
    const now = new Date().getTime();
    let distance = endDate - now;

    if (distance < 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  // Calculate valid until date function
  function calculateValidUntil() {
    if (!newEstate || !newEstate.end_date) {
      return '';
    }

    const endDate = new Date(newEstate.end_date);
    const validUntilDate = new Date(endDate.getTime()); // Subtract 30 days

    const formattedDate = validUntilDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    return formattedDate;
  }

  // Update time remaining and valid until date every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
      setValidUntil(calculateValidUntil());
    }, 1000);
    return () => clearInterval(interval);
  }, [newEstate]);

  useEffect(() => {
    if (timeRemaining && validUntil) {
      if (!timeRemaining?.days && !timeRemaining?.hours && !timeRemaining?.minutes && !timeRemaining?.seconds) {
        alert("You Proposal Ended");
        window.location.href = "/login"
      }
    }
  }, [timeRemaining])

  return (
    <div className='first_section_container'>
      <div className='overlay'></div>
      <div className='fs_content'>
        <div className="fs-text">
        <div className='left_side'>
          <h2>THANK YOU</h2>
          <h5>FOR CHOOSING</h5>
        </div>
        <img src={premierLogo}/>
        <div className="right_side">
           <h2>Premier</h2>
           <h5>Group</h5>
        </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
